<template>
  <!--begin::Card-->
  <div class="">
    <!--begin::Header-->
    <!-- <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">Reset Password</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Change your account password</span
        >
      </div>
     
    </div> -->
    <!--end::Header-->
    <!--begin::Form-->

    <form class="form" id="kt_password_change_form">
      >

      <div class="pb-5 pt-lg-0 pt-5">
        <!-- <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"> -->
        <img class="mb-6" src="/media/logos/Main_Logo.png" height="50px;" />
        <!-- </h3> -->
        <h3 class="loginheader">Change an Password</h3>
        <span class="loginheaderbelow"
          >Please change an strong password to secure your account</span
        >
      </div>

      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 text-alert text-middle"
          >Account Email</label
        >
        <div class="col-xl-3 col-lg-3">
          <label class="text-middle">{{ user }}</label>
        </div>
      </div>
      <div class="form-group">
        <label class=""> New Password </label>
        <input
          :type="switchVisibility(showNewPassword)"
          class="form-control form-control-lg form-control-solid"
          value=""
          placeholder="New password"
          name="new_password"
          ref="new_password"
        />
        <inline-svg
          width="15"
          height="15"
          src="media/svg/icons/General/lock-icon.svg"
          class="lockicon"
        />
        <span class="eyeicon"
          ><v-icon
            icon
            small
            v-if="showNewPassword"
            @click="showNewPassword = !showNewPassword"
            >mdi-eye</v-icon
          ></span
        >
        <span class="eyeicon"
          ><v-icon
            small
            v-if="!showNewPassword"
            @click="showNewPassword = !showNewPassword"
            >mdi-eye-off</v-icon
          >
        </span>
      </div>
      <div class="form-group">
        <label class="">Verify Password </label>
        <input
          :type="switchVisibility(showVerifyPassword)"
          class="form-control form-control-lg form-control-solid"
          value=""
          placeholder="Verify password"
          name="verify_password"
          ref="verify_password"
        />
        <inline-svg
          width="15"
          height="15"
          src="media/svg/icons/General/lock-icon.svg"
          class="lockicon"
        />
        <span class="eyeicon">
          <v-icon
            small
            v-if="showVerifyPassword"
            @click="showVerifyPassword = !showVerifyPassword"
            >mdi-eye</v-icon
          ></span
        >
        <span class="eyeicon">
          <v-icon
            small
            v-if="!showVerifyPassword"
            @click="showVerifyPassword = !showVerifyPassword"
            >mdi-eye-off</v-icon
          >
        </span>
      </div>

      <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
        <button
          type="submit"
          @click="save()"
          ref="kt_save_changes"
          tabindex="7"
          class="btn btn-primary loginbutton"
        >
          Update Password
        </button>
      </div>
      <!-- <div class="loginFooteOuter text-center">
                    <span class="loginFooter">Have an account?
                      <a id="kt_login_signup" class="text-primary" tabindex="4" style="cursor:pointer"
                        @click="showForm('signin')">Login</a></span>
                  </div> -->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SAVE_PASSWORD } from "@/core/services/store/auth.module";
import KTUtil from "@/assets/js/components/util";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Swal from "sweetalert2";

export default {
  name: "ChangePassword",
  props: ["user", "passwordId"],
  data() {
    return {
      showNewPassword: false,
      showVerifyPassword: false,
      password: "",
      status: "",
      valid: true,
    };
  },
  mounted() {
    const password_change_form = KTUtil.getById("kt_password_change_form");
    const strongPassword = function () {
      return {
        validate: function (input) {
          const value = input.value;
          if (value === "") {
            return {
              valid: true,
            };
          }

          // Check the password strength
          if (value.length < 10) {
            return {
              valid: false,
            };
          }

          // The password does not contain any uppercase character
          if (value === value.toLowerCase()) {
            return {
              valid: false,
            };
          }

          // The password does not contain any uppercase character
          if (value === value.toUpperCase()) {
            return {
              valid: false,
            };
          }

          // The password does not contain any digit
          if (value.search(/[0-9]/) < 0) {
            return {
              valid: false,
            };
          }

          return {
            valid: true,
          };
        },
      };
    };
    this.fv = formValidation(password_change_form, {
      fields: {
        // current_password: {
        //   validators: {
        //     notEmpty: {
        //       message: "Current password is required"
        //     },
        //   }
        // },
        new_password: {
          validators: {
            notEmpty: {
              message: "New password is required",
            },
            checkPassword: {
              message:
                "The New password is too weak it need  to be minimum of 10 characters and contain on special character and one number and one upper case character",
            },
          },
        },
        verify_password: {
          validators: {
            notEmpty: {
              message: "Confirm password is required",
            },
            identical: {
              compare: function () {
                return password_change_form.querySelector(
                  '[name="new_password"]'
                ).value;
              },
              message: "The password and its confirm are not the same",
            },
            checkPassword: {
              message:
                "The Confirm password is too weak it need  to be minimum of 10 characters and contain on special character and one number and one upper case character",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
        submitButton: new SubmitButton(),
      },
    }).registerValidator("checkPassword", strongPassword);
  },
  methods: {
    switchVisibility(flag) {
      return flag ? "text" : "password";
    },
    save() {
      this.fv.validate();

      this.fv.on("core.form.valid", () => {
        var password = this.$refs.new_password.value;
        var passwordId = this.passwordId;
        const submitButton = this.$refs["kt_save_changes"];

        // set spinner to submit button
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(() => {
          // send update request
          this.$store
            .dispatch(SAVE_PASSWORD, { password, passwordId })
            // go to which page after successfully update
            .then(() => this.$router.push({ name: "login" }));

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        }, 2000);
      });

      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
        });
      });
    },
    cancel() {
      this.fv.resetForm();
      // this.$refs.current_password.value = "";
      this.$refs.new_password.value = "";
      this.$refs.verify_password.value = "";
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>

<style scoped>
.form-control.form-control-solid {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 15px;
  width: 459px;
  height: 50px;
  letter-spacing: -0.408px;
  font-feature-settings: "case" on;
  background: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  border-radius: 15px;
  text-align: left;
  position: relative;
  box-sizing: border-box;
  border: 2px solid #bfc1d4;
  padding-left: 39px;
  color: #6f7392;
}

.eyeicon {
  position: absolute;
  margin-left: 414px;
  margin-top: -36px;
}

.loginbutton {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  /* identical to box height, or 129% */
  text-align: center;
  letter-spacing: -0.408px;
  font-feature-settings: "case" on;

  color: #ffffff;
  width: 459px;
  border-radius: 10px;
}

.lockicon {
  position: absolute;
  margin-top: -35px;
  margin-left: 14px;
}

.form-check-label {
  font-style: normal;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height, or 147% */
  text-align: center;
  letter-spacing: -0.408px;
  font-feature-settings: "case" on;

  color: #1c1f37;
}

.form-group label {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  /* identical to box height, or 129% */
  letter-spacing: -0.408px;

  color: #1c1f37;
}

.card.card-custom > .card-header .card-title .card-label {
  font-size: 27px;
}

.user_mail {
  font-size: 20px;
}

.input_label {
  font-size: 17px;
}

#kt_password_change_form {
  width: 836px;
}
</style>
