<template>
  <div class="h-100 d-flex align-items-center justify-content-center">
    <div v-if="this.showCurrentTask" class="">
      <KTChangePassword :user="user" :passwordId="passwordId"></KTChangePassword>
    </div>

    <div v-if="!this.showCurrentTask" class="">
      <expired></expired>
    </div>
  </div>
</template>

<script>
import KTChangePassword from "@/view/pages/profile/forgotpassword/forgotpasswordaccess";
import { CHANGE_PASSWORD } from "@/core/services/store/auth.module";
import expired from "@/view/pages/profile/forgotpassword/forgotpasswordexpired";
export default {
  name: "custom-page",
  components: {
    expired,
    KTChangePassword,
  },
  data() {
    return {
      tabIndex: 0,
      user: "",
      passwordId: "",
      showCurrentTask: false,
    };
  },
  beforeMount() {
    this.getUnits();
  },

  methods: {
    /**
     * Set current active on click
     * @param event
     */
    getUnits: function () {
      var password = this.$route.params.password;
      this.passwordId = this.$route.params.password;

      this.$store
        .dispatch(CHANGE_PASSWORD, { password })
        .then((response) => {
          this.user = response.email;
          if (response.success) {
            this.showCurrentTask = true;
          } else {
            this.showCurrentTask = false;
            this.component = expired;
          }
        })
        .catch(() => {
          console.error("dispatch error");
        });
    },
  },
};
</script>
